import React, {useState} from "react"
import Layout from "../components/layout";
import "./share-your-story.css";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
// import addToMailchimp from "gatsby-plugin-mailchimp";
import addToMailchimp from "../services/mailchimp";
import globalStyle from "../styles/global.module.css";
import ContactEmailAddress from "../components/contactEmailAddress";
import formStyle from "./form.module.css";

const ShareMyStoryPage = ({data}) => {
  const [formState, setFormValues] = useState({
    email: '',
    fullName: '',
    permissionToShare: '',
    story: '',
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowErrorAlert] = useState({show: false, onField: ""});
  const [formResult, setFormResult] = useState({});
  const submitUrl = "https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=410c5093cc";

  const resetFormAfterSubmit = () => {
    setFormValues({
      email: '',
      fullName: '',
      permissionToShare: '',
      story: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = ["email", "name", "permissionToShare", "story"];
    for (const field in formState) {
      if(requiredFields.indexOf(field) !== -1) {
        if(!formState[field]) {
          setShowErrorAlert({
            show: true,
            onField: field.charAt(0).toUpperCase()+ field.slice(1)
          });

          return;
        }
      }
    }

    const result = await addToMailchimp(formState.email, {
      FNAME: formState.name,
      PERMISSION: formState.permissionToShare,
      STORY: formState.story,
    }, submitUrl);
    setFormResult(result);

    setShowAlert(true);

    if (result.result === 'success') {
      resetFormAfterSubmit();
    }
  };

  const handleFormChange = (e) => {
    setFormValues({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Layout className="site-content">
      <Container className="story-post-container">
        <div>
          <h1 className={globalStyle.entryTitle}>Share My Story</h1>
        </div>
        <div className={globalStyle.subTitle}>
          <p>How has the lack of Paid Family Leave impacted you? We would love to hear your story. Thank you for taking the time to share. </p>
        </div>

        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8}>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="email">
                <Form.Label className={[formStyle.formLabel, formStyle.required]}>Email address</Form.Label>
                <Form.Control type="email" name="email" value={formState.email} onChange={handleFormChange} />
              </Form.Group>

              <Form.Group controlId="fullName">
                <Form.Label className={[formStyle.formLabel, formStyle.required]}>Name</Form.Label>
                <Form.Control type="text" name="fullName" value={formState.fullName} onChange={handleFormChange} />
              </Form.Group>

              <fieldset>
                <Form.Group controlId="permission-to-share">
                  <Form.Label as="legend" className={[formStyle.formLabel, formStyle.required]}>
                    Do you consent to Moms on Maternity using and sharing your story on our site/social media?
                  </Form.Label>

                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="permissionToShare"
                    id="permission-to-share-yes"
                    value="Yes"
                    checked={formState.permissionToShare === 'Yes'}
                    onChange={handleFormChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Yes, but keep anonymous"
                    name="permissionToShare"
                    id="permission-to-share-yes-anonymous"
                    value="Yes, but keep anonymous"
                    checked={formState.permissionToShare === 'Yes, but keep anonymous'}
                    onChange={handleFormChange}
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="permissionToShare"
                    id="permission-to-share-no"
                    value="No"
                    checked={formState.permissionToShare === 'No'}
                    onChange={handleFormChange}
                  />
                </Form.Group>
              </fieldset>

              <Form.Group controlId="story">
                <Form.Label className={[formStyle.formLabel, formStyle.required]}>My story</Form.Label>
                <Form.Control as="textarea" value={formState.story} name="story" rows="3" onChange={handleFormChange} />
              </Form.Group>

              <Button variant="primary" type="submit" className={[globalStyle.btnPrimary, globalStyle.redirectButtonPink]}>Submit</Button>
            </Form>

            <Row>
              <Col>
                {
                  showAlert && formResult.result === 'error' &&
                  <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible
                  >Some error has occurred. Please send an email to <ContactEmailAddress/></Alert>
                }
                {
                  showAlert && formResult.result === 'success' &&
                  <Alert variant="primary" onClose={() => setShowAlert(false)} dismissible
                  >Thank you! We have received your email and will get back to you soon!</Alert>
                }
                {
                  showError.show &&
                  <Alert variant="danger" onClose={() => setShowErrorAlert({show: false})} dismissible
                  >{showError.onField} field should not be empty</Alert>
                }
              </Col>
            </Row>

          </Col>
        </Row>

      </Container>
    </Layout>
  )
};

export default ShareMyStoryPage
